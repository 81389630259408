#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2%;
}

#customers td, #customers th {
  border: 1px solid hwb(60 94% 5%);
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #afa96f;}

#customers tr:hover {background-color: rgb(166, 127, 91);}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #878201;
  color: white;
}
