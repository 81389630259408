.color{
    background-color: #85862d;
}

.color:hover{
    background-color: rgb(135, 100, 40);
    opacity: 5;
}

.container{
    margin-bottom:5%;
}

h2{
    padding: 10px;
    margin: 10px;
}