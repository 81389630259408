
.contact-section {
    padding: 3em 0;
  }

  
.wrapper {
    width: 100%;
    -webkit-box-shadow: 0px 21px 41px -13px rgba(168, 226, 59, 0.18);
    -moz-box-shadow: 0px 21px 41px -13px rgba(145, 214, 40, 0.18);
    box-shadow: 0px 21px 41px -13px #83ad1b2e;
    background-image: linear-gradient(rgb(148, 145, 51), rgb(106, 103, 40));
    opacity: 0.8;
    

  }


.form-control{
  height: 62px;
  background:transparent;
  color: white;
  font-size: 16px;
  border-radius: 10px;
  box-shadow: none !important;
  border: 1px solid;
  
}

textarea.form-control{
  height: inherit !important;
  margin-top: 5px;
}



.btn{
    padding: 12px 16px;
    cursor: pointer;
    border-width: 1px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
}
.btn-primary{
  background: rgb(98, 69, 28) !important;
  border-color: rgb(98, 69, 28) !important;
  color:white;
}

h1,h2,h3,h4,h5, .h1,.h2,h3,.h4,h5{
  line-height: 1.5;
  font-weight: 400;
  font-family: "poppins", Arial, sans-serif;
  color: white;
}

/* map Start */
.map{
  flex-grow: 3;
  width: 100%;
}

.gmap_iframe{
  width: 100%;
  height: 80vh;
  padding: 20px;
  margin-top: 30px;
  
}

.align-items-stretch{
  position: relative;
}

a{
  color:rgb(98, 69, 28);
}
