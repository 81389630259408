.h3{
    color: rgb(0, 0, 0);
}
p{
    text-align: justify;
}
li{
    text-align: justify;
}

.pic{
    margin-top: 15%;
}
.wrappers{
    width: 100%;
    -webkit-box-shadow: 0px 21px 41px -13px rgba(13, 15, 10, 0.18);
    -moz-box-shadow: 0px 21px 41px -13px rgba(13, 15, 10, 0.18);
    box-shadow: 0px 21px 41px -13px #83ad1b2e;
    background-image: linear-gradient(rgb(148, 145, 51), rgb(106, 103, 40));
    opacity: 0.8;
}